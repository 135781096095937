<template>
  <div>
    <v-row align="center" class="justify-center">
      <v-btn
            align='center'
            text
            color="primary" @click="print">Print
      </v-btn>
    </v-row>
    <v-row id="printMe">
      <v-card
        class="mx-auto mt-0"
        width="1000"
      >
        <v-card-title class="justify-center">
            <span class="title font-weight-light justify-center">{{controlDrug[0]['resident']['fullName']}}</span>
        </v-card-title>
      </v-card>
      <v-card
        class="mx-auto my-2"
        max-width="1000"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
              <p><b>Control Drug Name:</b> {{controlDrug[0]['name']}}</p>
              <p><b>Directive: </b>{{controlDrug[0]['directive']}}</p><br>
              <p><b>Pharmacy:</b> {{controlDrug[0]['pharmacy']}}</p><br>
              <p><b>Quantity Order:</b> {{controlDrug[0]['quantityOrder']}}</p><br>
              <p><b>Quantity Received:</b> {{controlDrug[0]['quantityReceived']}}</p><br>
               <p><b>Received Date & Time:</b> {{controlDrug[0]['receiveDateTime']}}</p><br>
              <p><b>Start Date:</b> {{controlDrug[0]['startDate']}}</p><br>
              <p><b>End Date:</b> {{controlDrug[0]['endDate']}}</p><br>
              <p><b>Witness:</b> {{controlDrug[0]['witness']}}</p><br>
            </div>
        </v-card-text>
        <img height="50" width="50" src="../../assets/logo.png" >
      </v-card>
    </v-row>
    </div>
</template>

<script>
import controlDrugApi from '../../services/api/ControlDrugRegisterAPI';
export default {
    name: 'controlDrugDetail',
    data() {
        return {
            controlDrug: '',
            resident: '',
            residentId: '',
            controlDrugId:''
        }
    },
    computed: {

    },
    created() {
        this.residentId = this.$route.params.residentId;
        this.controlDrugId = this.$route.params.controlId;
        this.get_registered_control_drug_list();
    },
    methods: {
       get_registered_control_drug_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              controlDrugApi.getRegisteredControlDrug({page: '', count:'', id:this.residentId, q:'', orderBy: '', order: ''}) 
                .then(controlDrug => {
                    this.controlDrug = controlDrug.result.filter(item => item.id === this.controlDrugId)
                    this.totalControlDrug = this.controlDrug.length
          
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        print () {
          this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style scoped>

</style>